import { ImageFocus } from 'core/models/card.model';
import { EmployeeConfiguration } from 'core/models/employee.model';
import { EnvironmentConfigurationData } from 'core/models/environment-configuration-data.model';
import { RewardCentreText } from 'core/text.service';

import { CountryFlag } from 'shared/components/country-flag/country-flag.model';
import { HeroVideoConfig } from 'shared/components/hero-layout/hero-layout.model';

export interface LoginInformation {
    loginConfiguration: LoginConfiguration;
    textByLanguage: RewardCentreText;
    environmentConfigurationData: EnvironmentConfigurationData;
    isRadioButtonFeatureFlagEnabled: boolean;
}

export interface LoginConfiguration {
    banner: SchemeContentFile;
    logo: SchemeContentFile;
    fontFamily: FontFamily;
    hero: LoginPageHeroConfig;
    supportedLanguages: SupportedLanguage[];
    language: SupportedLanguage;
}

export interface FontFamily {
    regular: SchemeContentFile;
    bold: SchemeContentFile;
    italic: SchemeContentFile;
    boldItalic: SchemeContentFile;
}

export interface ApplicationConfiguration {
    text: RewardCentreText;
    configuration: Configuration;
    environmentConfigurationData: EnvironmentConfigurationData;
    featureFlagsEnabled: FeatureFlag[];
}

export interface Configuration {
    options: Options;
    styling: Styling;
    navigationMenu: NavigationMenu;
    aggregatedLanguages: AggregatedLanguage[];
    language: AggregatedLanguage;
    disabledUntil?: Date;
    pageConfig: PageConfig;
    employeeConfiguration: EmployeeConfiguration;
    countryFlag: CountryFlag;
}

export interface Options {
    landingPage: LandingPage;
    defaultPrivacyValue: boolean;
    employeeNameDisplayFormat: EmployeeNameDisplayFormat;
    banner: SchemeContentFile;
    logo: SchemeContentFile;
    accountSettingsState: AccountSettingsState;
    invertedNavigationBarColor: boolean;
    displayLanguageSwitcherInMainNavigation: boolean;
    pageTitle: string;
    disabledLanguageIDs: number[];
}

export interface Styling {
    fontFamily: FontFamily;
    clientColour1: string;
    clientColour2: string;
    clientColour3: string;
    clientColour4: string;
    clientColour5: string;
    clientColour6: string;
    clientColour7: string;
    clientColour8: string;
    clientColour9: string;
    clientColour10: string;
    clientColour1TintedAndShadedColours: TintedAndShadedClientColours;
    clientColour2TintedAndShadedColours: TintedAndShadedClientColours;
    clientColour3TintedAndShadedColours: TintedAndShadedClientColours;
    statusColourEligible: string;
    statusColourActive: string;
    statusColourInCart: string;
    statusColourPending: string;
    statusColourActiveWithDeletion: string;
    statusColourTaskToComplete: string;
}

export class TintedAndShadedClientColours {
    clientColour50: string;
    clientColour100: string;
    clientColour200: string;
    clientColour300: string;
    clientColour400: string;
    clientColour450: string;
    clientColour500: string;
    clientColour600: string;
    clientColour700: string;
    clientColour800: string;
    clientColour900: string;
}

export interface SupportedLanguage {
    id: number;
    language: string;
    displayText: string;
    defaultCulture: string;
    numberCulture: string;
    currencyCulture: string;
    currencySymbol: string;
    currencySymbolPosition: CurrencySymbolPosition;
    dateCulture: string;
}

export interface AggregatedLanguage {
    id: number;
    language: string;
    displayText: string;
    defaultCulture: string;
    numberCulture: string;
    currencyCulture: string;
    currencySymbol: string;
    currencySymbolPosition: CurrencySymbolPosition;
    dateCulture: string;
    supportedCurrencies: SupportedCurrency[];
    currencyDecimalPlaces: number;
}

export interface SupportedCurrency {
    currencyID: string;
    isDefault: boolean;
    name: string;
    displayText: string;
    currencySymbol: string;
    currencySymbolPosition: CurrencySymbolPosition;
    decimalPlaces: number;
}

export enum CurrencySymbolPosition {
    None = 0,
    Prefixed = 1,
    PrefixedWithSpace = 2,
    Suffixed = 3,
    SuffixedWithSpace = 4
}

export const enum LandingPage {
    Home = 0,
    Selection = 1,
    Reimbursements = 2,
    TotalRewardStatement = 3
}

export enum EmployeeNameDisplayFormat {
    Default = 0,
    PreferredName = 1
}

export enum NextPage {
    Default = 0,
    Confirmation = 1
}

export enum AccountSettingsState {
    None = 0,
    Hidden = 1,
    Editable = 2,
    ReadOnly = 3
}

export interface SchemeContentFile {
    fileName: string;
    fileID: string;
    duration: number;
    filePath: string;
    thumbnailFilePath: string;
}

export interface PageConfig {
    disableAddRemoveDependant: boolean;
    home: HomeConfig;
    benefitManager: BenefitManagerConfig;
    engager: EngagerConfig;
    reimbursement: ReimbursementConfig;
    help: HelpConfig;
    hubPage: HubPageConfig;
    profile: ProfileConfig;
    lineManager: LineManagerConfig;
    applicationForm: ApplicationFormConfig;
}

export interface ProfileConfig {
    isEnabled: boolean;
    dependantsPageEnabled: boolean;
    beneficiaryPageEnabled: boolean;
    lifeEventsPageEnabled: boolean;
    agreementsAndCorrespondencePageEnabled: boolean;
}

export interface HomeConfig {
    isEnabled: boolean;
}
export interface BenefitManagerConfig {
    isEnabled: boolean;
    isSelectionFlowGuidanceEnabled: boolean;
    isPostSelectionEnabled: boolean;
}
export interface EngagerConfig {
    isEnabled: boolean;
}
export interface ReimbursementConfig {
    isEnabled: boolean;
}
export interface ApplicationFormConfig {
    isEnabled: boolean;
    applicationTypeLinks: ApplicationTypeLink[];
}
export interface ApplicationTypeLink {
    id: string;
    displayName: string;
}
export interface HelpConfig {
    isEnabled: boolean;
    contactUsPageEnabled: boolean;
    faqsPageEnabled: boolean;
    benefitInformationPageEnabled: boolean;
}
export interface HubPageConfig {
    isEnabled: boolean;
}
export interface LineManagerConfig {
    isEnabled: boolean;
}

export interface LoginPageHeroConfig {
    type: LoginPageBackgroundType;
    imageConfig: LoginImageConfig;
    videoConfig: HeroVideoConfig;
}

export const enum LoginPageBackgroundType {
    None = 0,
    White = 1,
    BrandColour = 2,
    Image = 3,
    Video
}

export interface LoginImageConfig {
    image: SchemeContentFile;
    imageFocus: ImageFocus;
    darkenImage: boolean;
}

export interface NavigationMenu {
    quickLinks: QuickLink[];
    quickLinkGroups: QuickLinkGroup[];
    hubPageNavigationDetails: HubPageNavigationDetail[];
}

export interface QuickLink {
    id: string;
    url: string;
    openInNewTab: boolean;
    name: string;
    description: string;
    isOutboundSsoLink: boolean;
    isMoreLink: boolean;
    isInternalLink: boolean;
    serviceProviderID: string;
    isContentDocument: boolean;
    document: SchemeContentFile;
    prepopulationDocument: PrepopulationDocument;
    pageID: string;
}

export interface QuickLinkGroup {
    groupConfigID: string;
    name: string;
    quickLinks: QuickLink[];
}

export interface HubPageNavigationDetail {
    id: string;
    displayName: string;
    pageID: string;
    displayInMenu: boolean;
}

export interface PrepopulationDocument {
    prepopulationDocumentID: string;
    fileName: string;
}

export interface CallToAction {
    type: CallToActionType;
    targetUrl: string;
    openInNewWindow: boolean;
    description: string;
    serviceProviderID: string;
    _label?: string;
    _ariaLabel?: string;
    _iconId: string;
}

export interface SchemeContent {
    schemeName: string;
}

export enum CallToActionType {
    None = 0,
    RedirectInternal = 1,
    RedirectExternal = 2,
    OpenOverlay = 3,
    OutboundSso = 4
}

export enum FeatureFlag {
    RCEventCard = 3,
    LeaverStatement = 4,
    PostSelectionV2 = 5,
    RewardEngagerBreakdownConfig = 50,
    FundingAccountCard = 59,
    RewardEngagerCardUpdate = 63,
    SelectionRadioButton = 64
}
