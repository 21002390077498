import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccordionComponent } from 'shared/components/accordion/accordion.component';
import { AccordionItemComponent } from 'shared/components/accordion/accordion-item.component';
import { OverlayAccordionHelper } from 'shared/components/accordion/overlay-accordion.helper';
import { AccordionTransparentComponent } from 'shared/components/accordion-transparent/accordion-transparent.component';
import { AccordionTransparentItemComponent } from 'shared/components/accordion-transparent/accordion-transparent-item.component';
import { BarChartComponent } from 'shared/components/bar-chart/bar-chart.component';
import { BeneficiaryDeleteComponent } from 'shared/components/beneficiary-delete/beneficiary-delete.component';
import { BeneficiaryEditComponent } from 'shared/components/beneficiary-edit/beneficiary-edit.component';
import { BenefitAssigneesComponent } from 'shared/components/benefit-assignees/benefit-assignees.component';
import { CarouselComponent } from 'shared/components/carousel/carousel.component';
import { CarouselItemComponent } from 'shared/components/carousel/carousel-item.component';
import { AdjudicationInfoTooltipComponent } from 'shared/components/claim-status/adjudiction-info-tooltip/adjudiction-info-tooltip.component';
import { ClaimStatusComponent } from 'shared/components/claim-status/claim-status.component';
import { ClickableContentCardComponent } from 'shared/components/content-card/clickable-content-card.component';
import { ContentOverlayComponent } from 'shared/components/content-overlay/content-overlay.component';
import { ContentRendererComponent } from 'shared/components/content-renderer/content-renderer.component';
import { CookiesOverlayComponent } from 'shared/components/cookies-overlay/cookies-overlay.component';
import { CostDifferenceComponent } from 'shared/components/cost-difference/cost-difference.component';
import { CountryFlagComponent } from 'shared/components/country-flag/country-flag.component';
import { DarwinSelectComponent } from 'shared/components/darwin-select/darwin-select.component';
import { DarwinSelectOptionComponent } from 'shared/components/darwin-select/darwin-select-option.component';
import { DatepickerComponent } from 'shared/components/datepicker/datepicker.component';
import { DatepickerService } from 'shared/components/datepicker/datepicker.service';
import { DatepickerCalendarOverlayComponent } from 'shared/components/datepicker/datepicker-calendar-overlay.component';
import { DependantDeleteComponent } from 'shared/components/dependant-delete/dependant-delete.component';
import { DependantEditComponent } from 'shared/components/dependant-edit/dependant-edit.component';
import { DependantEvidenceComponent } from 'shared/components/dependant-evidence/dependant-evidence.component';
import { DependantEvidenceViewComponent } from 'shared/components/dependant-evidence/dependant-evidence-view.component';
import { EvidenceUploadComponent } from 'shared/components/dependant-evidence/evidence-upload/evidence-upload.component';
import { DependantStatusComponent } from 'shared/components/dependant-status/dependant-status.component';
import { DependantStatusTooltipComponent } from 'shared/components/dependant-status/dependant-status-tooltip.component';
import { DonutChartComponent } from 'shared/components/donut-chart/donut-chart.component';
import { DropDownMenuComponent } from 'shared/components/drop-down-menu/drop-down-menu.component';
import { FaviconComponent } from 'shared/components/favicon/favicon.component';
import { FooterComponent } from 'shared/components/footer/footer.component';
import { FormInputCheckboxComponent } from 'shared/components/form-inputs/form-input-checkbox/form-input-checkbox.component';
import { FormInputNumberComponent } from 'shared/components/form-inputs/form-input-number/form-input-number.component';
import { FormInputRangeComponent } from 'shared/components/form-inputs/form-input-range/form-input-range.component';
import { FundingAccountBreakdownComponent } from 'shared/components/funding-account-breakdown/funding-account-breakdown.component';
import { FundingAccountExpiryInfoComponent } from 'shared/components/funding-account-expiry-info/funding-account-expiry-info.component';
import { FundsOverlayComponent } from 'shared/components/funds-overlay/funds-overlay.component';
import { GoBackLinkComponent } from 'shared/components/go-back-link/go-back-link.component';
import { HeroLayoutComponent } from 'shared/components/hero-layout/hero-layout.component';
import { IconComponent } from 'shared/components/icon.component/icon.component';
import { ImageBackgroundComponent } from 'shared/components/image-background/image-background.component';
import { InformationContentComponent } from 'shared/components/information-content/information-content.component';
import { LanguageSelectComponent } from 'shared/components/language-select/language-select.component';
import { SkeletonComponent } from 'shared/components/loading/skeleton/skeleton.component';
import { SkeletonDirective } from 'shared/components/loading/skeleton/skeleton.directive';
import { NavBarMenuComponent } from 'shared/components/nav-bar-menu/nav-bar-menu.component';
import { PaginatorComponent } from 'shared/components/paginator/paginator.component';
import { PendingFieldValuesComponent } from 'shared/components/pending-field-values/pending-field-values.component';
import { PrivacyToggleComponent } from 'shared/components/privacy-toggle/privacy-toggle.component';
import { PrivacyToggleCotentComponent } from 'shared/components/privacy-toggle-popup/privacy-toggle-content/privacy-toggle-content.component';
import { PrivacyTogglePopupComponent } from 'shared/components/privacy-toggle-popup/privacy-toggle-popup.component';
import { ProfileCardEditComponent } from 'shared/components/profile-card-edit/profile-card-edit.component';
import { ProfileFieldViewComponent } from 'shared/components/profile-field-view/profile-field-view.component';
import { ProfileFieldsEditComponent } from 'shared/components/profile-fields-edit/profile-fields-edit.component';
import { ProgressiveImageComponent } from 'shared/components/progressive-image/progressive-image.component';
import { ScrollToTopArrowComponent } from 'shared/components/scroll-to-top-arrow/scroll-to-top-arrow.component';
import { SecondaryNavigationComponent } from 'shared/components/secondary-navigation/secondary-navigation.component';
import { DarwinMessageTagComponent } from 'shared/components/tags/darwin-message-tag/darwin-message-tag.component';
import { DarwinStatusTagComponent } from 'shared/components/tags/darwin-status-tag/darwin-status-tag.component';
import { DarwinTagComponent } from 'shared/components/tags/darwin-tag/darwin-tag.component';
import { CustomSpecifiedDateComponent } from 'shared/components/testing-mode/custom-specified-date/custom-specified-date.component';
import { TestingModeComponent } from 'shared/components/testing-mode/testing-mode.component';
import { ToggleComponent, ToggleComponentOffLabelDirective, ToggleComponentOnLabelDirective } from 'shared/components/toggle/toggle.component';
import { ValidationErrorComponent } from 'shared/components/validation-error/validation-error.component';
import { ValidationMessageComponent } from 'shared/components/validation-message/validation-message.component';
import { VideoBackgroundComponent } from 'shared/components/video-background/video-background.component';
import { WelcomeOverlayComponent } from 'shared/components/welcome-overlay/welcome-overlay.component';
import { AppTextDirective } from 'shared/directives/appText.directive';
import { CardWidthDirective } from 'shared/directives/card-width.directive';
import { FloatingPanelDirective } from 'shared/directives/floating-panel.directive';
import { FocusTrapDirective } from 'shared/directives/focus-trap.directive';
import { FormatCurrencyDirective } from 'shared/directives/format-currency.directive';
import { ImageFocusDirective } from 'shared/directives/image-focus.directive';
import { InputAccessibilityDirective } from 'shared/directives/input-accessibility.directive';
import { LineClampTextDirective } from 'shared/directives/line-clamp-text.directive';
import { MouseOverEffectDirective } from 'shared/directives/mouse-over-effect.directive';
import { ShowOnScrollDirective } from 'shared/directives/show-on-scroll.directive';
import { ThrottleClickDirective } from 'shared/directives/throttle-click.directive';
import { AppTextPipe } from 'shared/pipes/app-text.pipe';
import { ArrayFilterPipe } from 'shared/pipes/array-filter.pipe';
import { BooleanAppTextPipe } from 'shared/pipes/boolean-app-text.pipe';
import { CostPeriodPipe } from 'shared/pipes/cost-period.pipe';
import { DependentEvidenceStatusToStatusPipe } from 'shared/pipes/dependant-evidence-status-to-tag-status.pipe';
import { DependentStatusToStatusPipe } from 'shared/pipes/dependant-status-to-tag-status.pipe';
import { FormatCurrencyPipe } from 'shared/pipes/format-currency.pipe';
import { FormatDatePipe } from 'shared/pipes/format-date.pipe';
import { FormatEnumPipe } from 'shared/pipes/format-enum.pipe';
import { FormatNamePipe } from 'shared/pipes/format-name.pipe';
import { FormatNumberPipe } from 'shared/pipes/format-number.pipe';
import { FormatPercentPipe } from 'shared/pipes/format-percent.pipe';
import { FormatString } from 'shared/pipes/format-string.pipe';
import { MonetaryValuePendingPipe } from 'shared/pipes/monetaryValue-pending.pipe';
import { MonetaryValueValuePipe } from 'shared/pipes/monetaryValue-value.pipe';
import { OrderByPipe } from 'shared/pipes/order-by.pipe';
import { TrustHtmlPipe } from 'shared/pipes/trust-html.pipe';
import { TrustStylePipe } from 'shared/pipes/trust-style.pipe';
import { TooltipModule } from 'shared/tooltip/tooltip.module';
import { TooltipService } from 'shared/tooltip/tooltip.service';
import { VideoComponent } from 'shared/video/video.component';
import { VideoOverlayComponent } from 'shared/video/video-overlay/video-overlay.component';

@NgModule({
    declarations: [
        HeroLayoutComponent,
        IconComponent,
        DependantDeleteComponent,
        DependantEditComponent,
        BeneficiaryEditComponent,
        AppTextDirective,
        CardWidthDirective,
        ImageFocusDirective,
        FormatCurrencyDirective,
        MouseOverEffectDirective,
        FocusTrapDirective,
        LineClampTextDirective,
        ThrottleClickDirective,
        AppTextPipe,
        OrderByPipe,
        ArrayFilterPipe,
        FormatCurrencyPipe,
        FormatNumberPipe,
        FormatPercentPipe,
        FormatDatePipe,
        FormatNamePipe,
        MonetaryValuePendingPipe,
        MonetaryValueValuePipe,
        TrustHtmlPipe,
        TrustStylePipe,
        BooleanAppTextPipe,
        CostPeriodPipe,
        FormatString,
        FormInputCheckboxComponent,
        FormInputNumberComponent,
        FormInputRangeComponent,
        FooterComponent,
        ToggleComponent,
        ToggleComponentOnLabelDirective,
        ToggleComponentOffLabelDirective,
        ProgressiveImageComponent,
        ImageBackgroundComponent,
        VideoBackgroundComponent,
        CarouselComponent,
        CarouselItemComponent,
        AccordionComponent,
        AccordionItemComponent,
        AccordionTransparentComponent,
        AccordionTransparentItemComponent,
        VideoComponent,
        VideoOverlayComponent,
        DonutChartComponent,
        WelcomeOverlayComponent,
        BenefitAssigneesComponent,
        BeneficiaryDeleteComponent,
        ScrollToTopArrowComponent,
        PrivacyToggleComponent,
        FloatingPanelDirective,
        DatepickerComponent,
        DatepickerCalendarOverlayComponent,
        ProfileFieldsEditComponent,
        ValidationErrorComponent,
        ValidationMessageComponent,
        PaginatorComponent,
        InputAccessibilityDirective,
        LanguageSelectComponent,
        FundingAccountBreakdownComponent,
        BarChartComponent,
        FundingAccountExpiryInfoComponent,
        ContentRendererComponent,
        ContentOverlayComponent,
        ClickableContentCardComponent,
        DependantEvidenceComponent,
        EvidenceUploadComponent,
        CookiesOverlayComponent,
        ProfileFieldViewComponent,
        PrivacyTogglePopupComponent,
        PrivacyToggleCotentComponent,
        ProfileCardEditComponent,
        CountryFlagComponent,
        DependantStatusComponent,
        DependantStatusTooltipComponent,
        PendingFieldValuesComponent,
        DependantEvidenceViewComponent,
        InformationContentComponent,
        TestingModeComponent,
        CustomSpecifiedDateComponent,
        ClaimStatusComponent,
        CostDifferenceComponent,
        AdjudicationInfoTooltipComponent,
        NavBarMenuComponent,
        DarwinSelectComponent,
        DropDownMenuComponent,
        DarwinSelectOptionComponent,
        DarwinMessageTagComponent,
        DarwinStatusTagComponent,
        DarwinTagComponent,
        DependentEvidenceStatusToStatusPipe,
        DependentStatusToStatusPipe,
        SecondaryNavigationComponent,
        FormatEnumPipe,
        FaviconComponent,
        GoBackLinkComponent,
        FundsOverlayComponent,
        ShowOnScrollDirective,
        SkeletonComponent,
        SkeletonDirective
    ],
    exports: [
        TooltipModule,
        HeroLayoutComponent,
        IconComponent,
        DependantDeleteComponent,
        DependantEditComponent,
        BeneficiaryEditComponent,
        AppTextDirective,
        CardWidthDirective,
        ImageFocusDirective,
        FormatCurrencyDirective,
        MouseOverEffectDirective,
        FocusTrapDirective,
        LineClampTextDirective,
        ThrottleClickDirective,
        AppTextPipe,
        BooleanAppTextPipe,
        CostPeriodPipe,
        OrderByPipe,
        ArrayFilterPipe,
        FormatCurrencyPipe,
        FormatNumberPipe,
        FormatPercentPipe,
        FormatDatePipe,
        FormatNamePipe,
        MonetaryValuePendingPipe,
        MonetaryValueValuePipe,
        TrustHtmlPipe,
        TrustStylePipe,
        FormatString,
        FormInputCheckboxComponent,
        FormInputNumberComponent,
        FormInputRangeComponent,
        FooterComponent,
        ToggleComponent,
        ToggleComponentOffLabelDirective,
        ToggleComponentOnLabelDirective,
        ProgressiveImageComponent,
        ImageBackgroundComponent,
        VideoBackgroundComponent,
        CarouselComponent,
        CarouselItemComponent,
        AccordionComponent,
        AccordionItemComponent,
        AccordionTransparentComponent,
        AccordionTransparentItemComponent,
        VideoComponent,
        VideoOverlayComponent,
        DonutChartComponent,
        WelcomeOverlayComponent,
        BenefitAssigneesComponent,
        BeneficiaryDeleteComponent,
        ScrollToTopArrowComponent,
        PrivacyToggleComponent,
        FloatingPanelDirective,
        DatepickerComponent,
        DatepickerCalendarOverlayComponent,
        ProfileFieldsEditComponent,
        ValidationErrorComponent,
        ValidationMessageComponent,
        PaginatorComponent,
        InputAccessibilityDirective,
        LanguageSelectComponent,
        FundingAccountBreakdownComponent,
        BarChartComponent,
        FundingAccountExpiryInfoComponent,
        ContentRendererComponent,
        ContentOverlayComponent,
        ClickableContentCardComponent,
        EvidenceUploadComponent,
        CookiesOverlayComponent,
        ProfileFieldViewComponent,
        PrivacyTogglePopupComponent,
        PrivacyToggleCotentComponent,
        ProfileCardEditComponent,
        CountryFlagComponent,
        DependantStatusComponent,
        DependantStatusTooltipComponent,
        AdjudicationInfoTooltipComponent,
        DependantEvidenceViewComponent,
        InformationContentComponent,
        TestingModeComponent,
        ClaimStatusComponent,
        CostDifferenceComponent,
        AdjudicationInfoTooltipComponent,
        NavBarMenuComponent,
        DarwinSelectComponent,
        DropDownMenuComponent,
        DarwinSelectOptionComponent,
        DarwinMessageTagComponent,
        DarwinStatusTagComponent,
        DarwinTagComponent,
        DependentEvidenceStatusToStatusPipe,
        DependentStatusToStatusPipe,
        SecondaryNavigationComponent,
        FormatEnumPipe,
        FaviconComponent,
        GoBackLinkComponent,
        FundsOverlayComponent,
        ShowOnScrollDirective,
        SkeletonComponent,
        SkeletonDirective
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TooltipModule],
    providers: [DatepickerService, TooltipService, FormatNamePipe, OverlayAccordionHelper, CostPeriodPipe]
})
export class InternalSharedModule {
    static forRoot(): ModuleWithProviders<InternalSharedModule> {
        return {
            ngModule: InternalSharedModule,
            providers: [TooltipModule.rootProviders()]
        };
    }
}
